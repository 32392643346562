import React from "react";
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import {Link} from 'gatsby';
import { graphql } from 'gatsby';

import HeaderImg from '../assets/images/contact-us/contact-us.webp';
import AndroidTipsImg from '../assets/images/contact-us/android-tips.svg';
import IOSTipsImg from '../assets/images/contact-us/ios-tips.svg';
import ContactSupportImg from '../assets/images/contact-us/contact-support.svg';
import ContactCorporateImg from '../assets/images/contact-us/contact-corporate.svg';
import LocationImg from '../assets/images/contact-us/location.svg';
import FacebookImg from '../assets/images/contact-us/contact-facebook.webp';
import TwitterImg from '../assets/images/contact-us/contact-twitter.webp';
import InstagramImg from '../assets/images/contact-us/contact-instagram.webp';
import SecurityLockImg from '../assets/images/contact-us/security-lock.svg';
import HrLineImg from '../assets/images/contact-us/hr-line.svg';
import HelpCenterImg from '../assets/images/contact-us/help-center.svg';

const ContactUs = ({data}) => {

    return (
        <Layout env={data.site.siteMetadata.env}>
        <SEO
            postTitle='Contact Us | BlueMail App' 
            postDescription='If we‘ve missed a feature you are looking for in BlueMail, or you would like to give us some feedback, please contact us for any question you may have.'
            postImage='/img/OG/contact-us.png'
            postURL='contact-us'
            postSEO
        />
        <Navbar />
        
        <div className="contact-us-area container pt-120 pb-100">
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center'}}>
                    <img src={HeaderImg} alt='BlueMail Contact Us'/>
                </div>
            </div>

            <div className='row mt-50'>
                <div className='col-lg-8 col-md-7 col-sm-12 contact-section1' style={{maxWidth: '100%'}}>
                    <div className='row'>
                        <div className='col-12 contactus-main'>
                            <h2>General Help & Questions</h2>
                            <p style={{maxWidth: '800px'}}>Find helpful tutorials and tips on how to use BlueMail and make it customized just for you.</p>
                        </div>
                    </div>
                    <div className='contact-box'>
                        <a href='https://bluemail.me/help' target='_blank' className='contact-box-card'>
                            <div className='contact-box-card-items'>
                                <div className='contact-box-card-item-icon'>
                                    <img className="help-center" src={HelpCenterImg} alt='BlueMail Android' />
                                </div>
                                <div className='contact-box-item-content'>
                                    <h4>Help Center</h4>
                                    <p>Have a question?<br/><Link style={{color: '#1F6BF1'}} to="https://bluemail.me/help" target='_blank'>Learn more</Link> about BlueMail</p>
                                </div>
                            </div>
                        </a>                        
                    </div>
                    <h2 className='contactus-main' style={{margin: '10px 0 0 0'}}>Contact Us</h2>
                    <p>If we've missed a feature you are looking for in BlueMail, or you would like to give us some feedback, please contact us for any question you may have</p>
                    <div className='contact-box'>
                        <a href='mailto:support@bluemail.me' className='contact-box-card'>
                            <div className='contact-box-card-items'>
                                <div className='contact-box-card-item-icon'>
                                    <img src={ContactSupportImg} alt='BlueMail Android' />
                                </div>
                                <div className='contact-box-item-content'>
                                    <h4>Contact Support</h4>
                                    <p>Have a question?<br/>Send us an email and we'll help!</p>
                                </div>
                            </div>
                        </a>
                        <a href='mailto:sales@bluemail.me' className='contact-box-card'>
                            <div className='contact-box-card-items'>
                                <div className='contact-box-card-item-icon'>
                                <img src={ContactCorporateImg} alt='BlueMail Android' />                                    </div>
                                <div className='contact-box-item-content'>
                                    <h4>Contact Corporate</h4>
                                    <p>Are you a business?<br/>Reach out to discuss a corporate solution</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <a className="p-a" href='https://blix.net'><img style={{marginRight: "5px"}} src={LocationImg}/> Blix Inc., 101 Hudson Street, Jersey City, NJ 07302</a>
                    <br />
                    <br />
                    <br />
                </div>
                
                <div className='col-lg-4 col-md-5 col-sm-12 contactus-main' style={{padding: '0 35px'}}>
                    <div className="row">
                        <div className="col-12 sections2">
                            <h3>Press & Media - Get In Touch</h3>
                            <p>We love working with journalists around the world to share our message.</p>
                            <p>If you would like to discuss more, shoot us an email <a href='mailto:press@blix.net' style={{color: '#1F6BF1'}}>here</a></p>
                            <p>
                                <Link to='/press-kit/'>
                                    <span style={{fontWeight: '100', fontSize: '20px', marginRight: '5px'}}>&#187;</span> 
                                    See our Press Kit
                                </Link>
                            </p>
                            <p><img src={HrLineImg} /></p>
                            <p>
                                <Link to='/security/'>
                                    <img style={{marginRight: '5px'}} src={SecurityLockImg} /> Security Overview
                                </Link>
                            </p>
                            <h3>Follow Us</h3>
                            <p>For news, tips and updates on new releases from BlueMail.</p>
                            <div className='contactus-social-btn mt-30'>
                                <a href='https://www.facebook.com/BlueMailApp/'><img className="socials" src={FacebookImg} alt='BlueMail facebook'/></a>
                                <a href='https://twitter.com/BlueMail'><img src={TwitterImg} alt='BlueMail twitter'/></a>
                                <a href="https://www.instagram.com/bluemail_/"><img src={InstagramImg} alt='BlueMail instagram'/></a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
        <Footer />
        </Layout>
    )
}

export default ContactUs;

export const query = graphql`
query ContactUsPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
